import React from "react";
import Userlist from "../components/Userlist";

const User = () => {
  return (
    <div>
      <Userlist />
    </div>
  );
};

export default User;
